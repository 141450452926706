import Vuex from "vuex";
import Vue from "vue";

import { BigNumber } from 'bignumber.js'
import axios from 'axios'

import abiMetaWomen from '@/abi/metawomen.json'

BigNumber.config({ EXPONENTIAL_AT: 100 })

const ADDR_TOKEN_MW = ''
const MAXIMUM_MINT_TOKEN = 10000;
const MAXIMUM_AIRDROP_MINT_TOKEN = 3500;

const MAXIMUM_BULK_AIRDROP_TOKEN = 200;
const MAXIMUM_BULK_FREESALEMINT_TOKEN = 1;
const MAXIMUM_BULK_PRESALEMINT_TOKEN = 5;
const MAXIMUM_BULK_MAINSALEMINT_TOKEN = 10;

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    account: null,
    messageContent: null,
    messageType: null,
    contracts: {
        tokenMetaWomen: null,
    },
    metaWomen: {
        totalSupply: 0,
        airdropSupply: 0,
        price: BigNumber,
        unlockTime: 0,
    },
  },
  mutations: {
    init(state) {
        state.contracts.tokenMetaWomen = new window.web3.eth.Contract(abiMetaWomen, ADDR_TOKEN_MW);
        state.metaWomen.price = 0;
    },
    set_account(state,account) {
        state.account = account
    },
    show_info(state,message) {
        state.messageContent = message
        state.messageType = 'info'
    },
    show_success(state,message) {
        state.messageContent = message
        state.messageType = 'success'
    },
    show_error(state,message) {
        state.messageContent = message
        state.messageType = 'error'
    },
    show_warning(state,message) {
        state.messageContent = message
        state.messageType = 'warning'
    },
    read_metaWomen(state) {
        state.contracts.tokenMetaWomen.methods.totalSupply().call().then((ret)=>{
            state.metaWomen.totalSupply = BigNumber(ret);
            }).catch((error)=>{
            console.error("totalSupply",error)
        });

        state.contracts.tokenMetaWomen.methods.getAirdropSupply().call().then((ret)=>{
          state.metaWomen.airdropSupply = BigNumber(ret);
          }).catch((error)=>{
          console.error("airdropSupply",error)
      });

        if (state.account != null) {
          state.contracts.tokenMetaWomen.methods.getPrice(state.account.address).call().then((ret)=>{
            state.metaWomen.price = BigNumber(ret);
            }).catch((error)=>{
            console.error("getPrice",error)
          });          
        }
        else {
          state.contracts.tokenMetaWomen.methods.getPrice().call().then((ret)=>{
            state.metaWomen.price = BigNumber(ret);
            }).catch((error)=>{
            console.error("getPrice",error)
          });             
        }
    },
  },
  method: {
    fetchData(url){
        axios.get(url).then(response => {
           console.log(response);
        })
    },
  },
  actions: {
    /*---------------- Mumbai Testnet -------------------*/
    /*
    connect({commit}) {
      window.ethereum.request({ 
          method: 'eth_requestAccounts' 
      }).then((accounts) => {
          if(accounts.length==0) {
              console.log("No connected");
          } else {
            window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x13881' }],
            }).then(() => {
              console.log("wallet_switchEthereumChain")
              const account = {
                address: accounts[0],
                //balance: BigNumber(balance,"ether")
              }

              commit('set_account',account)
              commit('read_metaWomen')
              localStorage.setItem("MetaWomenAddress", account.address);
            }).catch(error => {
              console.log("error:wallet_switchEthereumChain",error)
              if (error.code==4902 || error.code==-32603) {
                window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [{ 
                    chainId: '0x13881', 
                    chainName: 'Matic Mumbai',
                    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
                    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
                    nativeCurrency: {
                      name: 'Mumbai',
                      symbol: 'MATIC',
                      decimals: 18
                    }
                  }],
                }).then(() => {
                  const account = {
                    address: accounts[0],
                  }
                  commit('set_account',account)
                  commit('read_metaWomen')
                  localStorage.setItem("MetaWomenAddress", account.address);
                }).catch(() => {
                  console.log("error:wallet_switchEthereumChain")
                });
              }
            });
          }
      }).catch((err) => {
        if (err.code === 4001) {
          console.log('Please connect to MetaMask.');
        } else {
          console.error(err);
        }
      });  
    }, */
    /*---------------- Polygon Mainnet -------------------*/
    
    connect({commit}) {
        window.ethereum.request({ 
            method: 'eth_requestAccounts' 
        }).then((accounts) => {
            if(accounts.length==0) {
                console.log("No connected");
            } else {
              window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x89' }],
              }).then(() => {
                console.log("wallet_switchEthereumChain")
                const account = {
                  address: accounts[0],
                  //balance: BigNumber(balance,"ether")
                }
                commit('set_account',account)
                commit('read_metaWomen')
                localStorage.setItem("MetaWomenAddress", account.address);
              }).catch(error => {
                console.log("error:wallet_switchEthereumChain",error)
                if (error.code==4902 || error.code==-32603) {
                  window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{ 
                      chainId: '0x89', 
                      chainName: 'Polygon Mainnet',
                      rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
                      blockExplorerUrls: ['https://polygonscan.com'],
                      nativeCurrency: {
                        name: 'Polygon',
                        symbol: 'MATIC',
                        decimals: 18
                      }
                    }],
                  }).then(() => {
                    const account = {
                      address: accounts[0],
                    }
                    commit('set_account',account)
                    commit('read_metaWomen')
                    localStorage.setItem("MetaWomenAddress", account.address);
                  }).catch(() => {
                    console.log("error:wallet_switchEthereumChain")
                  });
                }
              });
            }
        }).catch((err) => {
          if (err.code === 4001) {
            console.log('Please connect to MetaMask.');
          } else {
            console.error(err);
          }
        });  
    },
    disconnect({state}) {
      localStorage.removeItem("MetaWomenAddress");
      state.account = null
    },
    mint({state,commit}, params) {
        if (state.account==null)
        {
          commit('show_warning', 'Please connect Wallet!');
          return;         
        }

        if (state.metaWomen.totalSupply >= MAXIMUM_MINT_TOKEN)
        {
          commit('show_warning', 'No More Tokens!');
          return;
        }

        if (params.countOfToken > MAXIMUM_BULK_MAINSALEMINT_TOKEN || params.countOfToken <= 0)
        {
          commit('show_warning', 'Mint count error!');
          return;
        }

        state.contracts.tokenMetaWomen.methods.isOnAirdropList(state.account.address).call().then((ret)=>{
          if (ret) {
            if (state.metaWomen.airdropSupply >= MAXIMUM_AIRDROP_MINT_TOKEN) {
              commit('show_warning', 'No More Tokens for Airdrop!');
              return;
            }

            if (Number(params.countOfToken) > MAXIMUM_BULK_AIRDROP_TOKEN) {
              commit('show_warning', 'Airdrop max supply exceeded');
              return;              
            }

            state.contracts.tokenMetaWomen.methods.mainMint(params.countOfToken).send({
                from: state.account.address
            }).then(()=>{
                commit('show_success', 'Minted successfully!');
                commit('read_metaWomen');
                return;
            })            
          }
          else {
            state.contracts.tokenMetaWomen.methods.isOnFreesaleList(state.account.address).call().then((ret)=>{
              if (ret) {
                if (Number(params.countOfToken) > MAXIMUM_BULK_FREESALEMINT_TOKEN) {
                  commit('show_warning', 'Freesale max supply exceeded');
                  return;              
                }

                state.contracts.tokenMetaWomen.methods.mainMint(params.countOfToken).send({
                    from: state.account.address
                }).then(()=>{
                    commit('show_success', 'Minted successfully!');
                    commit('read_metaWomen');
                    return;
                })            
              }
              else {
                state.contracts.tokenMetaWomen.methods.getStatus().call().then((ret)=>{
                    if (ret == 0) {
                      commit('show_warning', 'Mint not started!');
                      return;                            
                    }
                    else if (ret == 1) {   // presale
                      state.contracts.tokenMetaWomen.methods.isOnPresalePeriod().call().then((ret)=>{
                          if (ret == 0) {
                            commit('show_warning', 'Presale is ended!');
                            return;                      
                          }
                          else {
                            state.contracts.tokenMetaWomen.methods.isOnPresaleList(state.account.address).call().then((ret)=>{
                              if (ret == 0){
                                commit('show_warning', 'You can not mint for presale!');
                                return;
                              }
                              else {
                                state.contracts.tokenMetaWomen.methods._presaleMinted(state.account.address).call().then((ret)=>{
                                  if (ret >= MAXIMUM_BULK_PRESALEMINT_TOKEN)
                                    {
                                      commit('show_warning', 'You can not mint tokens anymore');
                                      return;
                                    }
                                    else if ((Number(ret) + Number(params.countOfToken)) > MAXIMUM_BULK_PRESALEMINT_TOKEN)
                                    {
                                      commit('show_warning', 'Presale max supply exceeded');
                                      return;                                 
                                    }
                                    else {
                                      let totalPrice = state.metaWomen.price * params.countOfToken;
                                      state.contracts.tokenMetaWomen.methods.mainMint(params.countOfToken).send({
                                          from: state.account.address,
                                          value: BigNumber(totalPrice).integerValue().toString()
                                      }).then(()=>{
                                          commit('show_success', 'Minted successfully!');
                                          commit('read_metaWomen');
                                      })
                                    }
                                  }).catch((error)=>{
                                  console.error("_presaleMinted",error)
                                });
                              }
                            }).catch((error)=>{
                              console.error("isOnPresaleList",error)
                            });
                          }
                        }).catch((error)=>{
                        console.error("isOnPresalePeriod",error)
                      });
                    }
                    else if (ret == 2) {  // main sale
                      state.contracts.tokenMetaWomen.methods._mainsaleMinted(state.account.address).call().then((ret)=>{
                          if (ret >= MAXIMUM_BULK_MAINSALEMINT_TOKEN)
                          {
                            commit('show_warning', 'You can not mint tokens anymore');
                            return;
                          }
                          else if ((Number(ret) + Number(params.countOfToken)) > MAXIMUM_BULK_MAINSALEMINT_TOKEN)
                          {
                            commit('show_warning', 'Mint max supply exceeded');
                            return;                                 
                          }
                          else {
                            let totalPrice = state.metaWomen.price * params.countOfToken;
                            state.contracts.tokenMetaWomen.methods.mainMint(params.countOfToken).send({
                                from: state.account.address,
                                value: BigNumber(totalPrice).integerValue().toString()
                            }).then(()=>{
                                commit('show_success', 'Minted successfully!');
                                commit('read_metaWomen');
                            })
                          }
                        }).catch((error)=>{
                        console.error("_mainsaleMinted",error)
                      });
                    }
                    else if (ret == 3){
                      commit('show_warning', 'Mint End!');
                    }
                }).catch((error)=>{
                    console.error("getStatus",error)
                });
              }
            });
          }
        });
    },
  }
})
