<template>
  <fixed-header>
    <div class="navbar">
      <notification v-if="hasMessage"/>
      <nav
        class="
          navbar navbar-expand-lg
          align-items-center
          text-white
          navbar-dark
          py-1
          px-2 px-md-0 px-lg-1
          container
        "
      >
        <a class="navbar-brand text-white" href="/">
          <img style="width:50px" src="../assets/img/logo.png" alt="" class="mr-1"/>
          <img style="width:100px" src="../assets/img/logo_text.png" alt="" />
        </a>
        <button
          class="navbar-toggler text-white"
          type="button"
          @click="collapes = !collapes"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapses navbar-collapse d-none">
          <div class="d-flex align-items-center justify-content-md-end w-100">
            <ul class="navbar-nav align-items-md-center text-sm mr-1">
              <li class="nav-item my-1 my-sm-0 mr-1">
                <a class="nav-link" href="#">Home</a>
              </li>
              <li class="nav-item my-1 my-sm-0 mr-1">
                <a class="nav-link" href="#consulting">Consulting</a>
              </li>
              <li class="nav-item my-1 my-sm-0 mr-1">
                <a class="nav-link" href="#training">Academy</a>
              </li>
              <li class="nav-item my-1 my-sm-0 mr-1">
                <a class="nav-link" href="#recruitment">Recruitment</a>
              </li>
              <li class="nav-item my-1 my-sm-0 mr-1">
                <a class="nav-link" href="#network">Network</a>
              </li>

            </ul>
            <div class="socialsite-icon mr-1">
             <!-- <a href="https://twitter.com/" class="link" target="_blank">
                <img src="../assets/img/twitter.svg" class="img-fluid" />
              </a> -->
            </div>


          </div>
        </div>
        <!-- <div class="collapses navbar-collapse d-none">
 
        </div> -->
      </nav>
      <transition name="fade">
        <div class="d-lg-none res-nav" v-if="collapes === true">
          <div class="d-flex flex-column align-items-center py-2 py-md-0">
            <div class="d-flex align-items-center my-2">
              <div class="socialsite-icon mr-1">

            </div>
            <div class="socialsite-icon mr-1">

            </div>
 
          </div>

            <ul class="navbar-nav align-itemss-center ml-lg-auto mt-1 text-sm">
              <li class="nav-item my-1">
                <a
                  class="nav-link text-whites"
                  @click="collapes = collapse"
                  href="#"
                  >Home</a
                >
              </li>
              <li class="nav-item my-1">
                <a
                  class="nav-link text-whites"
                  @click="collapes = collapse"
                  href="#consulting"
                  >Consulting</a
                >
              </li>
              <li class="nav-item my-1">
                <a
                  class="nav-link text-whites"
                  @click="collapes = collapse"
                  href="#training"
                  >Academy</a
                >
              </li>
              <li class="nav-item my-1">
                <a
                  class="nav-link text-whites"
                  @click="collapes = collapse"
                  href="#recruitment"
                  >Recruitment</a
                >
              </li>
              <li class="nav-item my-1">
                <a
                  class="nav-link text-whites"
                  @click="collapes = collapse"
                  href="#network"
                  >Network</a
                >
              </li>


            </ul>
          </div>
        </div>
      </transition>
    </div>
  </fixed-header>
</template>

<script>
import FixedHeader from "vue-fixed-header";
import Notification from '@/components/notification.vue';

export default {
  components: {
    FixedHeader,
    Notification
  },
  data() {
    return {
      collapes: false,
    };
  },
  beforeMount() {
  },
  mounted() {
    var localAddress = localStorage.getItem("ProjectNameAddress");
      try {
          if (localAddress && localAddress.length > 0) {
              this.$store.dispatch("connect");
          }
      } catch (error) {
          return;
      }
  },
  computed: {
    isMetaMaskInstalled() {
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask)
    },
    isMetaMaskConnected() {
        return this.$store.state.account!=null;
    },
    hasMessage() {
        return this.$store.state.messageContent!=null
    }
  },
  methods:{
    connectWallet() {
        const { ethereum } = window;       
        if (!(ethereum && ethereum.isMetaMask))
        {
          this.$store.dispatch("show_warningmessage", {
                message:"Please install MetaMask!"
                })

          return;
        }
 
        this.$store.dispatch("connect")   
    },
    lockMetamask() {
        this.$store.dispatch("disconnect")
    },
    showWallet(wallet) {
        return (
            wallet.substring(0, 6) +
            "..." +
            wallet.substring(wallet.length - 6, wallet.length)
        );
    },
  },
};
</script>

<style>
  .btn-primary {
    border-radius: 25px !important;
    background-image: linear-gradient(#7355b0, #6975dd);
    border: none;
    font-weight:normal;
    padding: 1rem 2.5rem !important;
    font-size: 1.3rem !important;
    box-shadow: 0px 0px 10px -1px #6975dd;
  }
  .icons {
    font-size: 15px !important;
  }
  .nav-link {
    color: #ffffff !important;
    text-align: center;
    border-radius: 50px;
    padding: 7px 15px !important;
    transition: all 0.5s;
    font-weight: 600;
  }
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:focus {
    background-image: linear-gradient(#7355b0, #6975dd);
    box-shadow: 0px 0px 6px -1px #6975dd;
  }
  .navbar.vue-fixed-header--isFixed {
    position: fixed;
    left: 0;
    top: 0;
    background: #131212;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 0 5px 1px rgba(255, 255, 255, 0.2);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .res-nav {
    position: absolute;
    background: #131212;
    top: 64px;
    left: 0;
    width: 100% !important;
    z-index: 1000;
  }

  .socialsite-icon img{
    width: 30px;
    height: 30px;
  }
</style>
