<template>
  <div class="bg-main">
    <Navbar />
    <div class="container text-center text-white px-2 py-2">
      <h1>The Web3 People</h1>

      <p
        class="
          mx-auto
          pb-3
          pt-1
          px-0 px-md-3
        "
      >
        A Web3 hub connecting the best Web3 industry leaders
      </p>

<!--
       <div class="border justify-content-center"> 
         <img class="col px-0" src="../../assets/img/banner.png" alt="" /> 
      </div> 
-->
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/navbar.vue";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
  .bg-main {
    background-image: url("../../assets/img/s.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  p {
    font-size:18px;
  }
  .bg-main h1 {
    font-size: 70px;
    font-family: "Playfair Display", serif;
    font-weight: 900;
  }

  @media (max-width: 540px) {
    .bg-main h1 {
      font-size: 35px;
    }
  }
</style>
