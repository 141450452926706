<template>
  <div class="home">
    <Header />
<!-- ----------------------------------------------------------------------------- -->
      <section id="consulting" class="pt-100">
        <div class="col-md-12 d-flex flex-column align-items-center pt-0">
          <img
            src="../assets/img/consulting.png"
            width="300px"
            class="mb-30"
            alt=""
          />
<p class="para text-left text-white col-sm-9 mb-0">
Whether you are in a corporation or a small to medium Web3 businesses, we have solutions for you. It can be difficult getting started and launching a brand in the fast moving web3 industry without a large team of established experts on your side. Our experienced Marketing and Tech specialists provide consulting on all aspects of Web3 such as NFT and Crypto projects, and offer marketing, promotion and product development services to help you quickly launch your project sucessfully and reach the moon!
	</p>
          </div>
        </section>
 <!-- ----------------------------------------------------------------------------- -->
    <div>
      <section id="training" class="pt-100">
        <div class="col-md-12 d-flex flex-column align-items-center pt-0">
          <img
            src="../assets/img/training.png"
            width="270px"
            class="mb-30"
            alt=""
          />

<p class="para text-center text-white col-sm-9 mb-0">
Our Web3 Academy helps you break into the web3 industry and take advantage of the latest innovations and trends. We offer industry leading education and recognised certifications in both blockchain development and specialist training to help non-technical professionals to stand apart as web3 experts. We also provide continuing professional development for blockchain talent to stay on top of new technologies and emerging trends at all levels of expertise.
<br> <br>After your training, we provide continued support to alumni by matching you with our Web3 employer partners.Our global network of business partners are diverse with representatives all over the world. We specialize in the APAC region including Australia, Singapore, China, India, Japan, Korea, Indonesia, Philippines, and embrace the future of work with job opportunities for remote, flexible and part-time work.
</p>
        </div>
      </section>
      <!-- ----------------------------------------------------------------------------- -->

        
      <section id="recruitment" class="pt-100">
        <div class="col-md-12 d-flex flex-column align-items-center pt-0">
          <img
            src="../assets/img/Recruitment.png"
            width="270px"
            class="mb-30"
            alt=""
          />

<p class="para text-center text-white col-sm-9 mb-0">
Our recruitment services simplify the process of blockchain recruiting. With the rapid growth of the Blockchain, Crypto and NFT industries, being able to source the best talent rapidly is essential to the success of your business. Our recruitment services utilise our extensive alumni and partner networks to secure you the best development, operations and marketing talent in the industry.
Our thorough vetting process and strong network and community attracts the highest performing 20% of blockchain talent that drive 80% of results. In this fast paced competitive market, having the best and brightest minds is key to seizing first mover advantage in new niches and achieving success.
</p>
        </div>
      </section>
       <!-- ----------------------------------------------------------------------------- -->
             <section id="network" class="pt-100">
        <div class="col-md-12 d-flex flex-column align-items-center pt-0">
          <img
            src="../assets/img/Network.png"
            width="270px"
            class="mb-30"
            alt=""
          />

<p class="para text-center text-white col-sm-9 mb-0">
Access the Web3 People’s extensive network and make new friends with industry leaders in our community of Web3 Entrepreneurs, developers, professionals and creators.
Grow your network and build a brighter future together with the Web3 People!
<br><br>
</p>
        </div>
      </section>
      <Roadmap />
      <Team />
      <FAQ />
    </div>
    <Footer />
  </div>
  </template>

<script>
  import Header from "@/components/Header/index.vue";
 // import Mint from "@/components/mint.vue";
 // import Roadmap from "@/components/roadmap.vue";
//  import Team from "@/components/team.vue";
 // import FAQ from "@/components/faq.vue";
  import Footer from "@/components/Footer/index.vue";
  export default {
    components: {
      Header,
   //   Mint,
  //    Roadmap,
  //    Team,
  //    FAQ,
      Footer,
    },
  };
</script>

<style>
  .home {
    background-image: url("../assets/img/main.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
</style>
